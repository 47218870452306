// Here you can add other styles

body{
    background-color: #ffffff;
}

.app-header .navbar-brand{
    width: 250px;
}

.table-head {
    padding-bottom: 15px;
}

.sidebar .nav{
  width: 250px;
}

.pagination{
  margin: 20px 0;
}

.button-wrapper-data .btn {
  margin: 0 10px;
}

.action-coloumn .btn {
  margin-right: 13px;
  color: #ffffff;
}

.Forgot_wrapper {
    max-width: 600px;
    margin: 50px auto;
    position: absolute;
    right: 0;
    left: 0;
}

.pagination-bottom {
  display: none;
}

.Forgot_wrapper .error {
    color: red;
}

.error {
    padding: 10px 0;
    color: red;
}

.form-wrapper .input-group > div {
    width: 100%;
}

.questionHead > h2 {
    font-size: 20px;
    padding: 20px 0 10px 0;
}

ul.list-block {
    padding: 0;
    list-style: none;
    margin: 20px -15px;
}

.m-b-10{
    margin-bottom: 20px;
}


.button-wrapper .btn {
    margin: 6px 11px;
}

.bottom-button-wrapper .btn-primary {
    margin-right: 12px;
}

.bottom-button-wrapper .btn-success {
    min-width: 150px;
}

.bottom-button-wrapper {
    margin-top: 20px;
}

.packagequestion {
    position: relative;
    margin: 20px 0;
}

.packagequestion .btn-danger {
    position: absolute;
    top: 0;
    right: 300px;
}

form.form-section .btn-success {
    margin-right: 20px;
}

.input-wrapper {
    max-width: 629px;
}

.question-header h3 {
    margin: 0;
    padding-top: 20px;
    text-align: left;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 600;
}
form.form-section {
    padding-bottom: 50px;
}
.question-header {
    max-width: 629px;
    margin-bottom: -7px;
}


.button-wrapper {
    position: absolute;
    right: 0;
    top: -6px;
    left: 47%;
}
ul.list-block li span {
  display: block;
}
.animated.fadeIn > h1 {
  text-align: center;
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 0.100em;
  font-weight: 600;
}

ul.list-block li {
  width: 30.3%;
  background-color: #00b1e1;
  float: left;
  margin: 10px;
  padding: 14px;
  color: #ffffff;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
}

.submit-btn .btn {
  background-color: #00b1e1;
  color: #ffffff;
  width: 100%;
  padding: 13px 40px;
}

.signup_form.white-box .form-group > .signup-width {font-weight: 600;}

.signup_form.white-box {
  background-color: #0000000f;
  padding: 20px;
  margin: 0 0 20px 0;
}

.signup_form.white-box h2 {
  margin-bottom: 35px;
}

ul.landline-block li > label {
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    padding-left: 10px;
}

.form-wrapper .btn {
  margin-top: 12px;
}

.form-wrapper > h3 {
  font-size: 18px;
  font-weight: 600;
}

td.button-wrapper-td .btn {
  margin: 0 10px;
}

// Loader

/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }
  
  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .form-group.type-checkbox ul > li {
      padding-bottom: 14px;
      list-style: none;
  }

label.signup-width ul {
    padding: 0;
}

.fileContainer.file-upload label {
  width: 100px;
  background-color: #00b1e1;
  height: 30px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px;
  cursor: pointer;
}

.sidebar .nav-link{
  padding: 15px 12px;
  text-transform: capitalize;
  letter-spacing: 0.0100em;
}

.form-group.type-checkbox ul {
    padding-left: 0;
    padding: 20px 0 0;
}
span.cateogry-file {
  display: block;
  width: 384px;
  overflow: hidden;
  margin: 0 auto;
  padding: 20px 0;
  height: 200px;
}

.fileContainer.file-upload ~ input {
  opacity: 0;
}

.fileContainer.file-upload input {
  opacity: 0;
  display: none;
}

.fileContainer.file-upload label {width: 100px;background-color: #00b1e1;height: 30px;display: inline-block;}

  .loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  }
  
  /* Animation */
  
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  // Sidebar styling

  .sidebar-fixed .sidebar{
    width: 250px;
  }

  html:not([dir="rtl"]) .sidebar{
    margin-left: -250px;
  }

  .sidebar .sidebar-nav{
    width: 250px;
  }

  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer{
    margin-left: 250px;
  }

  .p-4.login-wrapper.card {
    background-color: #273878;
}

.login-admin-wrapper > h1 {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}

.login-admin-wrapper p {
    text-align: center;
    color: #ffffff !important;
    font-size: 17px;
    font-weight: 700;
}

.Forgot_wrapper.p-4.card .input-group {
  display: block;
}

a.back-link {
  display: block;
  padding-bottom: 10px;
  color: #ffffff;
  font-size: 15px;
}

.login-admin-wrapper .input-group .form-control {
    border: 2px solid #ffffff;
}



.Forgot_wrapper.p-4.card {
  background-color: #273878;
}

.Forgot_wrapper.p-4.card > h2 {
  color: #ffffff;
  font-weight: 600;
}

.Forgot_wrapper.p-4.card p {
  font-weight: 600;
  color: #ffffff;
}

.Forgot_wrapper .enter-block {
  color: #ffffff;
  font-weight: 600;
}

thead.table-header > td {
  font-weight: 600;
  font-size: 16px;
}

.mb-3.input-group .form-control {
  border-radius: 0;
}

.submit-forgotbtn > button {
  width: 100%;
  background-color: #000000;
  border: 0;
  color: #ffffff;
  font-weight: 600;
}

.login-admin-wrapper .px-4 {
    background-color: #000000;
    color: #ffffff;
    border: 0;
    padding: 10px 13px;
    text-transform: uppercase;
    width: 100%;
}

.category-modal .form-wrapper {
  margin-bottom: 15px;
}

.category-modal .form-wrapper input[type="file"] {
  border: 0;
  padding-left: 0;
}

.login-admin-wrapper .px-4 ~ a {
    display: block;
    padding-top: 20px;
    color: #ffffff;
    font-size: 17px;
}

td.table-action .btn-danger {
  background-color: red !important;
}

.p-4.login-wrapper.card h3 {
  text-align: center;
  font-size: 32px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.100em;
  font-weight: 600;
  padding-bottom: 14px;
}

.table-wrapper {
  margin: 30px 0;
}

a.navbar-brand {
  color: #ffffff !important;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: monospace;
  letter-spacing: 0.100em;
}

.button-wrapper-data .plans-link {
  background-color: #438e43;
  padding: 8px 15px;
  color: #ffff;
  border-radius: 4px;
  min-width: 88px;
  display: inline-block;
  text-align: center;
}

.Package_information_wrapper .list-one-head h3 {
  font-size: 19px;
}

.Package_information_wrapper ul.row {
  list-style: none;
  padding-left: 15px;
}

.react-datepicker__input-container input {
  border: 1px solid #00000073;
  border-radius: 4px;
  padding: 5px 10px;
}

.list-one .expirydate {
  padding-right: 13px;
  font-size: 19px;
  font-weight: 600;
}

.Package_information_wrapper ul.row li {
  display: inline-block;
  font-size: 14px;
  padding: 10px 0 10px 30px;
}

td.table-action .btn {
  background-color: #273878;
  color: #ffffff;
  margin: 0 12px;
}

.total-amount .btn {
  background-color: #273878;
  color: #ffffff;
  padding: 12px 32px;
}

.Package_information_wrapper .list-one-head h3 {
  font-size: 19px;
  background-color: #273878;
  padding: 12px;
  border-left: 7px solid #000000;
  color: #ffffff;
  font-weight: 600;
  margin: 16px 0 10px;
}

.total-amount span {
  background-color: #273878;
  display: block;
  color: #ffffff;
  padding: 10px;
  font-size: 19px;
}

.list-one .flex-wrap li > input[type="radio"] {
  opacity: 0;
  display: none;
}


.list-one .flex-wrap li > input[type="radio"] ~ label .listitem:before, .list-one .flex-wrap li > input[type="radio"] ~ label .listitem:after {
  position: absolute;
  content: '';
  left : 0;
  width: 20px;
  height: 20px;
  background-color: #273878;
  border-radius: 50%;
}

.list-one .flex-wrap li > input[type="radio"]:checked ~ label .listitem:after {
  background-color: #ffffff;
  width: 7px;
  height: 7px;
  left: 6px;
  top: 16px;
}

h2.Package_information.p-t-0 {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 25px;
}

.total-amount {
  margin-bottom: 16px;
}

.user-modal .form-wrapper {
  margin-bottom: 15px;
} 
.user-modal .btngrp {
  display: flex;
  justify-content: flex-end;
}
.user-modal .btngrp .submit {
  margin-right: 5px;
}
.user-modal .rt-tbody {
  min-width: 210px;
  overflow: overlay !important;
  height: 210px;
}


